import { TMButtonProps } from "src/ui/Button/MButton"

export enum ButtonDirection {
  COLUMN,
  ROW,
}

export interface TWizard {
  currentStep: number
  steps: IWizardStep[]
  onNext?: () => void
  onBack?: () => void
}

export interface TBaseWizard extends TWizard {
  ProgressBar?: React.ReactElement
  HeaderBox?: React.ElementType
  TopContent?: React.ReactElement
  ContentBox?: React.ElementType
}

export interface TFullscreenWizard extends TWizard {
  title?: React.ReactNode
  metaTitle?: string
  onClose?: () => void
}

export interface TInlineWizard extends TWizard {
  showProgress?: boolean
}

export interface IWizardStep {
  id?: string
  title?: React.ReactNode
  component: React.ReactNode
  onNext?: () => void
  onBack?: () => void
  nextButtonLabel?: string
  hideNextButton?: boolean
  backButtonLabel?: string
  nextButtonProps?: TMButtonProps
  backButtonProps?: TMButtonProps
  hideBackButton?: boolean
  border?: boolean
  direction?: ButtonDirection
  skip?: boolean
  footer?: React.ReactNode
  hideProgress?: boolean
  skipButton?: React.ReactNode
}
