import styled from "styled-components"

import { Divider } from "src/ui/Divider/Divider"
import { spacing } from "src/ui/spacing"
import { WizardButtonRow } from "src/ui/Wizard/WizardButtonRow"
import { TBaseWizard } from "src/ui/Wizard/wizardTypes"

export function BaseWizard({
  steps,
  currentStep,
  onNext,
  onBack,
  ProgressBar,
  HeaderBox,
  TopContent,
  ContentBox,
}: TBaseWizard) {
  const filteredSteps = steps.filter((step) => !step.skip)

  const step = filteredSteps[currentStep]

  const ContentBoxElement = ContentBox || DefaultContentBox

  const HeaderBoxElement = HeaderBox || DefaultHeaderBox

  return (
    <>
      <HeaderBoxElement>
        {!!TopContent && TopContent}

        {!!ProgressBar && ProgressBar}
      </HeaderBoxElement>

      <ContentBoxElement>
        <ComponentArea>{step?.component}</ComponentArea>

        <WizardButtonRow
          currentStep={currentStep}
          onNext={step?.onNext || onNext}
          onBack={step?.onBack || onBack}
          backButtonProps={step?.backButtonProps}
          nextButtonProps={step?.nextButtonProps}
          nextButtonLabel={step?.nextButtonLabel}
          backButtonLabel={step?.backButtonLabel}
          hideNextButton={step?.hideNextButton}
          hideBackButton={step?.hideBackButton}
          border={step?.border}
          direction={step?.direction}
          skipButton={step?.skipButton}
        />

        {step?.footer && (
          <Footer>
            <Divider /> {step.footer}
          </Footer>
        )}
      </ContentBoxElement>
    </>
  )
}

const ComponentArea = styled.div``

const Footer = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const DefaultContentBox = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

const DefaultHeaderBox = styled.div``
