import { IWizardStep } from "src/ui/Wizard/wizardTypes"

export function getNumberOfSteps(steps: IWizardStep[]) {
  return steps.filter((s) => {
    if (s.skip) return false

    if (s.hideProgress) return false

    return true
  }).length
}
